(function ($) {
  $(function () {
    //region Товар
    //region Слайдер
    var productFullSliderThumbs = new Swiper('.product-full__slider_thumbs', {
      spaceBetween: 11,
      // centeredSlides: true,
      // slidesPerView: 'auto',
      // touchRatio: 0.2,
      // slideToClickedSlide: true,
      // loop: true,
      // slidesPerView: 'auto',
      // slidesPerView: document.querySelectorAll('.product-full__slider_thumbs
      // .swiper-slide').length < 5 ?
      // document.querySelectorAll('.product-full__slider_thumbs
      // .swiper-slide').length : 5,
      slideToClickedSlide: true,
      loop: false,
      loopedSlides: 50,
      //width: 117,
      slidesPerView: 5,
      // slidesPerView: 'auto',
      // loop: true,
      freeMode: true,
      // loopedSlides: 5,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      followFinger: false,
      // setWrapperSize: true
    });
    var productFullSlider = new Swiper('.product-full__slider_big', {
      // spaceBetween: 0,
      // loop:true,
      // loopedSlides: 5,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: productFullSliderThumbs,
      },
    });
    // productFullSlider.controller.control = productFullSliderThumbs;
    // productFullSliderThumbs.controller.control = productFullSlider;
    //endregion
    //endregion

    //region Faq
    $('.faq__title').on('click', function () {
      var $this = $(this),
        $parent = $this.parent();
      $parent.toggleClass('js-active');
    });
    //endregion

    //region Бургер в шапке
    $('.top-line__burger').on('click', function () {
      $(this).toggleClass('top-line__burger_open');
      $('#nav').toggleClass('js-opened');
    });
    $(window).scroll(function(){
      var $window = $(window),
       $position = $window.scrollTop();
      if($position>130) {
        $('.top-line__burger').addClass('js-dark')
      } else {
        $('.top-line__burger').removeClass('js-dark')

      }
    });
    //endregion

    //region Изменение количество в инпуте
    $('.quantity-block__button').on('click', function () {
      var $this = $(this),
        $num = parseInt($this.data('num')),
        $input = $this.parent().find('input'),
        $inputNum = parseInt($input.val());
      // Сложение
      if ($num > 0) {
        $input.val($inputNum + $num);
      }
      else {
        // Вычитание, если цифра в инпуте 2 и более
        if ($inputNum > 1) {
          $input.val($inputNum + $num);
        }
      }
    });
    //endregion

    //Chosen
    $("select").chosen({disable_search_threshold: 10});

    //region Корзина /cart
    // Поиск скидки
    $('#trigger-discount').on('click', function (e) {
      e.preventDefault();
      $('.cart__discount-form').slideToggle();
      $('.cart__promocode-form').slideUp();
      $('.cart__certificate-form').slideUp();
    });

    // Промокод
    $('#trigger-promocode').on('click', function (e) {
      e.preventDefault();
      $('.cart__promocode-form').slideToggle();
      $('.cart__discount-form').slideUp();
      $('.cart__certificate-form').slideUp();
    });

    // Сертификат
    $('#trigger-certificate').on('click', function (e) {
      e.preventDefault();
      $('.cart__certificate-form').slideToggle();
      $('.cart__discount-form').slideUp();
      $('.cart__promocode-form').slideUp();
    });

    // Форма авторизации
    $('.cart__auth-text').on('click', function (e) {
      e.preventDefault();
      $('.cart__auth-form').slideToggle();
    });
    //endregion

  });
})(jQuery);

